import React from "react";
import { connect } from "react-redux";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import styled from "styled-components/macro";
import { Alert as MuiAlert } from "@material-ui/lab";
import { getter } from "@progress/kendo-react-common";
import {
  responseInvite,
  proceedInvites,
} from "./../../redux/actions/userActions";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { InputText, InputPassword } from "./../../components/Input";
import { Paper } from "@material-ui/core";
import { spacing } from "@material-ui/system";
const pwdRegex = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$&%^&!*`])[A-Za-z0-9~@#$&%^&!*`]{8,20}$/
);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const Alert = styled(MuiAlert)(spacing);
const fnValidator = (value) => (!value ? "Fistname must be provided." : "");
const lnValidator = (value) => (!value ? "Lastname must be provided." : "");
const pwdGetter = getter("password");
const conPWDGetter = getter("conpassword");
const comparePWDValidator = (values) => {
  if (pwdGetter(values) == "" && conPWDGetter(values) == "") return;
  if (
    pwdGetter(values) != "" &&
    conPWDGetter(values) != "" &&
    pwdGetter(values) == conPWDGetter(values) &&
    pwdRegex.test(pwdGetter(values)) &&
    pwdRegex.test(conPWDGetter(values))
  )
    return;
  if (pwdGetter(values) != "" && !pwdRegex.test(pwdGetter(values)))
    return {
      password:
        "Password must consist at least 1 Uppercase and lowercase character, 1 digit, one special characters and 8 -20 characters length.",
    };

  if (conPWDGetter(values) != "" && !pwdRegex.test(conPWDGetter(values)))
    return {
      conpassword:
        "Password must consist at least 1 Uppercase and lowercase character, 1 digit, one special characters and 8 -20 characters length.",
    };

  if (pwdGetter(values) != conPWDGetter(values))
    if (pwdGetter(values) == "") {
      return {
        password: "Password and confirm password are not matched.",
      };
    } else {
      return {
        conpassword: "Password and confirm password are not matched.",
      };
    }

  return;
};

const states = ["NSW", "QLD", "ACT", "VIC", "WA", "SA", "NT", "TAS"];

class InviteResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: "",
      token: "",
      saved: false,
    };
  }

  componentDidMount() {
    let raws = window.location.href.split("?");
    if (raws.length != 2) window.location.href = "/auth/sign-in";
    let temp = raws[1].split("&");
    if (temp.length != 2) window.location.href = "/auth/sign-in";
    let action = temp[0].split("=")[1];
    let token = temp[1].split("=")[1];
    let data = { action: action, token: token };
    this.props.responseInvite(data);
    setTimeout(() => {
      this.setState({
        action: data.action,
        token: data.token,
      });
    }, 1000);
  }

  componentWillUnmount() {}

  handleSubmit = (event) => {
    this.props.proceedInvites(this.state.token, event);
    setTimeout(() => {
      this.setState({ saved: true });
    }, 1000);
  };

  handleSignIn = () => {
    window.location.href = "/auth/sign-in";
  };

  render() {
    let content;
    if (this.props.pending == false && this.props.status == true) {
      if (this.state.action.toUpperCase() == "ACCEPT") {
        if (this.props.data.isNewUser == true) {
          content = (
            <Wrapper>
              <Form
                onSubmit={this.handleSubmit}
                validator={comparePWDValidator}
                initialValues={{
                  state: "NSW",
                  password: "",
                  conpassword: "",
                }}
                render={(formRenderProps) => (
                  <FormElement
                    style={{
                      width: "90%",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      You have accepted the invitation and your account has been
                      created. Now, please provide the mandatory information
                      below : <br />
                      <br />
                    </div>
                    <div style={{ width: "90%", textAlign: "center" }}>
                      <table style={{ width: "95%" }}>
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <label>
                                <b>* Mandatory Fields</b>
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>
                                First name{" "}
                                <small>
                                  <b>*</b>
                                </small>
                              </label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"firstname"}
                                id={"firstname"}
                                component={InputText}
                                validator={fnValidator}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>Middle name</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"middlename"}
                                id={"middlename"}
                                component={Input}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>
                                Last name{" "}
                                <small>
                                  <b>*</b>
                                </small>
                              </label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"lastname"}
                                id={"lastname"}
                                component={InputText}
                                validator={lnValidator}
                              />
                            </td>
                          </tr>
                          {/*<tr>
                            <td valign={"top"} width={"35%"}>
                              <label>Street Address</label>
                            </td>
                            <td valign={"top"} width={"1%"}>
                              :
                            </td>
                            <td width={"64%"}>
                              <Field
                                name={"address1"}
                                id={"address1"}
                                component={InputText}
                              />
                              <Field
                                name={"address2"}
                                id={"address2"}
                                component={InputText}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>Suburb</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"suburb"}
                                id={"suburb"}
                                component={InputText}
                                value={""}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>Post code</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                type={"input"}
                                name={"postcode"}
                                id={"postcode"}
                                component={InputText}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>State</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                component={DropDownList}
                                data={states}
                                name={"state"}
                                filterable={false}
                                disabled={false}
                              />
                            </td>
                          </tr>*/}
                          <tr>
                            <td width={"35%"}>
                              <label>Update Password</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"password"}
                                id={"password"}
                                value={""}
                                component={InputPassword}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width={"35%"}>
                              <label>Confirm Password</label>
                            </td>
                            <td width={"1%"}>:</td>
                            <td width={"64%"}>
                              <Field
                                name={"conpassword"}
                                id={"conpassword"}
                                component={InputPassword}
                                value={""}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td colSpan={3} align={"center"}>
                              <button
                                type={"submit"}
                                className="k-button k-primary"
                                disabled={false}
                              >
                                Submit
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type={"button"}
                                className="k-button k-primary"
                                onClick={this.handleSignIn}
                                disabled={false}
                              >
                                <div>Skip to Login</div>
                              </button>
                            </td>
                          </tr>
                          {!this.props.pending &&
                            this.props.status &&
                            this.state.saved && (
                              <tr>
                                <td colSpan={3}>
                                  <Alert mt={2} mb={3} severity="info">
                                    Your detail has been save successfully.
                                  </Alert>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </FormElement>
                )}
              />{" "}
            </Wrapper>
          );
        } else {
          content = (
            <Wrapper>
              <table style={{ width: "80%" }}>
                <tbody>
                  <tr>
                    <td align={"center"}>
                      <label>
                        You have accepted the invitation and linked to{" "}
                        <b>{this.props.data.entname}</b>. Please login and
                        verify.
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;&nbsp;</td>
                  </tr>
                  <tr>
                    <td align={"center"}>
                      <button
                        type={"submit"}
                        className="k-button k-primary"
                        onClick={this.handleSignIn}
                        disabled={false}
                      >
                        <div>Click Here To Login</div>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Wrapper>
          );
        }
      } else {
        content = (
          <Wrapper>
            <h3>The invitation has been denied successfully.</h3>
          </Wrapper>
        );
      }
    } else {
      if (this.props.error != null) {
        content = (
          <Wrapper>
            <table style={{ width: "80%" }}>
              <tbody>
                <tr>
                  <td align={"center"}>
                    <h3>
                      <b>{this.props.error.data}</b>
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td align={"center"}>
                    <button
                      type={"submit"}
                      className="k-button k-primary"
                      onClick={this.handleSignIn}
                      disabled={false}
                    >
                      <div>Click Here To Login</div>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Wrapper>
        );
      } else {
        content = (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
          </div>
        );
      }
    }

    return <React.Fragment>{content}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    pending: state.userReducer.pending,
    status: state.userReducer.status,
    error: state.userReducer.error,
    data: state.userReducer.data,
  };
};

const mapDispatchToProps = {
  responseInvite,
  proceedInvites,
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteResponse);
